<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-news-main" v-if="!isMobile">
      <v-card
        elevation="0"
        color="#f6f9fe"
        class="ml-0 mr-0 mb-5"
        style="border-radius: 10px"
        height="490"
      >
        <v-img
          loading="lazy"
          height="490"
          :src="infoNews.img_full"
          gradient="0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)"
        >
          <v-row class="ml-10 mr-10 mt-0" align="end" justify="space-between">
            <v-col class="text-center" cols="7">
              <p class="ligth-text text-left pb-2 mb-0">
                {{ infoNews.tagName }}
              </p>
              <p
                class="ligth-text title-banner-ligth"
                style="text-align: initial"
              >
                {{ infoNews.post_title }}
              </p>
              <v-layout justify-start>
                <v-btn
                  :to="{
                    name: 'newsDetails',
                    params: { id: infoNews.post_id },
                  }"
                  class="mb-8"
                  color="#fff"
                  style="color: #3957b9"
                  rounded
                >
                  <strong> ver más </strong>
                </v-btn>
              </v-layout>
            </v-col>
            <v-col class="text-center pt-1" cols="5">
              <v-btn @click="slidePrev" small dark fab icon>
                <img src="../../assets/icon/Boton-up.svg" />
              </v-btn>
              <hooper
                ref="carousel"
                :infiniteScroll="true"
                :vertical="true"
                style="height: 400px"
                :itemsToShow="2.5"
                :centerMode="true"
                :playSpeed="4000"
                :progress="true"
                :autoPlay="true"
                @slide="updateCarousel"
              >
                <template v-for="item in listNews">
                  <slide :key="item">
                    <v-card elevation="0" color="#0000">
                      <div
                        style="text-align: initial"
                        :class="item.class"
                        class="d-flex flex-no-wrap justify-space-between"
                      >
                        <div class="ml-5 mt-3">
                          <p
                            style="line-height: 1.5em"
                            class="title-banner-mini-ligth text-2-lines ligth-text mb-0"
                          >
                            {{ item.post_title }}
                          </p>
                          <p class="text-left text-2-lines ligth-text mb-0">
                            {{ item.post_excerpt }}
                          </p>
                          <!-- <div
                            class="text-left text-2-lines ligth-text mb-0"
                            v-html="item.description"
                          ></div> -->
                        </div>
                        <v-avatar class="ma-3" size="110" tile>
                          <v-img :src="item.img_small"></v-img>
                        </v-avatar>
                      </div>
                    </v-card>
                  </slide>
                </template>
              </hooper>
              <v-btn @click="slideNext" small dark fab icon>
                <img src="../../assets/icon/Boton-down.svg" />
              </v-btn>
            </v-col>
          </v-row>
        </v-img>
      </v-card>
    </div>

    <!-- MOBILE -->
    <div class="mb-sm-news-main" v-else>
      <hooper
        ref="carousel_mobile"
        :itemsToShow="1"
        :centerMode="true"
        style="height: 350px"
        class="hooper-app"
        v-if="listNews.length > 0"
        :progress="true"
        :autoPlay="true"
        :playSpeed="5000"
      >
        <slide v-for="data in listNews" :key="data.img_full">
          <v-card
            elevation="0"
            color="#f6f9fe"
            class="ml-0 mr-3 mb-5 mb-sm-news-main"
            height="330"
          >
            <v-img
              loading="lazy"
              class="d-flex align-end"
              height="330"
              :src="data.img_full"
              gradient="0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)"
            >
              <v-row class="mx-0 px-0">
                <v-col class="mx-0 px-0" cols="1">
                  <v-icon @click="slidePrev" dark> mdi-chevron-left </v-icon>
                </v-col>
                <v-col class="mx-0 px-0" cols="10">
                  <div class="ml-4 mr-4">
                    <div class="d-flex">
                      <label class="ligth-text text-tag-sm text-left pb-2 mb-0">
                        {{ data.tagName }}
                      </label>
                      <label class="ligth-text text-tag-sm ml-auto">
                        {{ data.post_date }}
                      </label>
                    </div>

                    <p
                      class="ligth-text title-banner-ligth-sm text-1-1-lines mb-1"
                    >
                      {{ data.post_title }}
                    </p>

                    <div
                      class="text-left text-2-lines ligth-text text-description-sm mb-0"
                      v-html="data.description"
                    ></div>

                    <v-layout justify-start>
                      <v-btn
                        :to="{
                          name: 'newsDetails',
                          params: { id: data.post_id },
                        }"
                        class="mb-8"
                        color="#fff"
                        style="color: #3957b9"
                        rounded
                      >
                        <strong> ver más </strong>
                      </v-btn>
                    </v-layout>
                  </div>
                </v-col>
                <v-col cols="1" class="mx-0 px-0">
                  <v-icon @click="slideNext" dark> mdi-chevron-right </v-icon>
                </v-col>
              </v-row>
            </v-img>
          </v-card>
        </slide>
        <hooper-progress
          style="margin-top: -8px; width: 96%"
          slot="hooper-addons"
        ></hooper-progress>

        <!-- <hooper-navigation slot="hooper-addons"></hooper-navigation> -->
      </hooper>
      <!-- </v-col>
        <v-col cols="1" class="mx-0 px-0 pt-15 mt-15"> 
          <v-icon> mdi-chevron-right </v-icon>  
        </v-col> -->
      <!-- </v-row> -->
    </div>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
  Progress as HooperProgress,
} from "hooper";
import "hooper/dist/hooper.css";
import { CATEGORY } from "@/utils/util.js";
import alert from "@/mixins/alert";
import Api from "@/utils/api";

export default {
  mixins: [alert],
  name: "NewsMain",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    HooperProgress,
  },
  watch: {
    "$refs.carousel": function (newD) {
      console.log(newD);
    },
    carouselData: function (newU) {
      this.$refs.carousel.slideTo(newU);
    },
    user(newUser) {
      if (newUser) {
        this.currentUser = newUser;
        this.getNews();
      }
    },
  },
  data() {
    return {
      CATEGORY: CATEGORY,
      show: null,
      carouselData: 0,
      infoNews: {},
      listNews: [],
      loadingAll: false,
      isMobile: false,
      currentUser: [],
    };
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        //this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");
        var docList = this.infoToken.document;
        //console.log(this.infoToken);

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getNews() {
      // this.loadingAll = true;
      let cc;
      let loginId;
      cc = this.currentUser.document_number;
      loginId = this.currentUser.profile.id;
      var data = {
        pais: this.currentUser.country,
        ciudad: this.currentUser.headquarters_city,
        sede: this.currentUser.headquarters,
      };
      Api.noAuth()
        .getViewtop5(cc, loginId,data)
        .then((resp) => resp.json())
        .then((data) => {
          this.loadingAll = false;
          this.listNews = data.data;
          if (this.listNews.length > 0) {
            for (let i = 0; i < this.listNews.length; i++) {
              this.listNews[i].post_date = new Date(this.listNews[i].post_date)
                .toISOString()
                .substr(0, 10);
              this.listNews[i].class = "opacity-banner";
              if (this.listNews[i].tags.length > 0) {
                this.listNews[i].tagName = this.listNews[i].tags[0];
              } else {
                this.listNews[i].tagName = "Otro";
              }
            }
            this.infoNews = this.listNews[0];
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingAll = false;
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    slidePrev() {
      if (screen.width >= 1023) {
        this.$refs.carousel.slidePrev();
      } else {
        this.$refs.carousel_mobile.slidePrev();
      }
    },
    slideNext() {
      if (screen.width >= 1023) {
        this.$refs.carousel.slideNext();
      } else {
        this.$refs.carousel_mobile.slideNext();
      }
    },
    updateCarousel(payload) {
      var slideFrom = 0;
      if (
        payload.currentSlide < 0 ||
        payload.currentSlide == this.listNews.length
      )
        this.carouselData = 0;
      else this.carouselData = payload.currentSlide;
      if (payload.slideFrom == 0) slideFrom = this.carouselData - 1;
      else slideFrom = payload.slideFrom;
      this.infoNews = this.listNews[this.carouselData];
      if (this.listNews[this.carouselData]) {
        this.listNews[this.carouselData].class = "opacity-banner-1";
      }
      this.listNews[payload.slideFrom].class = "opacity-banner";
    },
  },

  created() {
    this.checkIfMobile();
    this.getToken();
    window.addEventListener("resize", this.checkIfMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIfMobile);
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  mounted() {
    this.currentUser = this.$store.getters.getUser;
    if (this.currentUser) {
      this.getNews();
    }
  },
};
</script>
<style>
@import "../../assets/css/main.less";
.ligth-text {
  color: #fff;
}
.title-banner-ligth {
  font-size: 36px !important;
  font-family: "RobotoMedium";
  text-align: left;
}

.title-banner-mini-ligth {
  font-size: 20px !important;
  font-family: "RobotoMedium" !important;
  text-align: left !important;
}

.subtitle-banner-ligth {
  font-family: "RobotoLight";
}

.img-active {
  width: 200px;
}

.img-active-1 {
  width: 110px;
}

.opacity-banner {
  opacity: 0.2;
  position: relative;
  right: 0px;
}

.opacity-banner-1 {
  opacity: 1;

  -moz-transform: scale(1) translate(-10px, 1px);
  -webkit-transform: scale(1) translate(-10px, 1px);
  -o-transform: scale(1) translate(-10px, 1px);
  -ms-transform: scale(1) translate(-10px, 1px);
  transform: scale(1) translate(-10px, 1px);
  /* position: relative;
  right: 30px; */
}

.mb-sm-news-main {
  display: none;
}

@font-face {
  font-family: "AsapSemiBold";
  src: url("./../../assets/font/Asap/Asap-Bold.ttf");
}

@font-face {
  font-family: "ProximaNovaLight";
  src: url("./../../assets/font/ProximaNova/ProximaNova-Light.ttf");
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-news-main {
    display: none;
  }
  .mb-sm-news-main {
    display: block;
    text-align: initial;
  }
  .title-banner-ligth-sm {
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    font-family: "RobotoRegular";
  }
  .text-tag-sm {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    font-family: "RobotoRegular";
  }
  .text-description-sm {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    font-family: "RobotoRegular";
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-news-main {
    display: none;
  }
  .mb-sm-news-main {
    display: block;
    text-align: initial;
  }
  .title-banner-ligth-sm {
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    font-family: "RobotoRegular";
  }
  .text-tag-sm {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    font-family: "RobotoRegular";
  }
  .text-description-sm {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    font-family: "RobotoRegular";
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-news-main {
    display: none;
  }
  .mb-sm-news-main {
    display: block;
    text-align: initial;
  }
  .title-banner-ligth-sm {
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    font-family: "RobotoRegular";
  }
  .text-tag-sm {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    font-family: "RobotoRegular";
  }
  .text-description-sm {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    font-family: "RobotoRegular";
  }
  .hooper-progress {
    position: sticky !important;
    height: 5px;
  }

  .hooper-progress-inner {
    background-color: #466be3;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-news-main {
    display: none;
  }
  .mb-sm-news-main {
    display: block;
    text-align: initial;
  }
  .title-banner-ligth-sm {
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    font-family: "RobotoRegular";
  }
  .text-tag-sm {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    font-family: "RobotoRegular";
  }
  .text-description-sm {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    font-family: "RobotoRegular";
  }
  .hooper-progress {
    position: sticky !important;
    height: 5px;
  }

  .hooper-progress-inner {
    background-color: #466be3;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-news-main {
    display: none;
  }
  .mb-sm-news-main {
    display: block;
    text-align: initial;
  }
  .title-banner-ligth-sm {
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    font-family: "RobotoRegular";
  }
  .text-tag-sm {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    font-family: "RobotoRegular";
  }
  .text-description-sm {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    font-family: "RobotoRegular";
  }
  .hooper-progress {
    position: sticky;
    height: 5px;
  }

  .hooper-progress-inner {
    background-color: #466be3;
  }
}
</style>
